/* eslint-disable react/function-component-definition */
import React, { createContext, useEffect } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'

const LastVisitedPageContext = createContext<string | null>(null)

export const LastVisitedPageProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
   const location = useLocation()
   const [searchParams] = useSearchParams()

   useEffect(() => {
      if (location.pathname.includes('dashboard')) {
         localStorage.setItem(
            'lastVisitedPage',
            searchParams.size > 0 ? `${location.pathname}?${searchParams.toString()}` : `${location.pathname}`
         )
      }
   }, [location.pathname])

   return <LastVisitedPageContext.Provider value={location.pathname}>{children}</LastVisitedPageContext.Provider>
}

export default LastVisitedPageContext
