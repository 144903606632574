import { styled } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import TableCell from '@mui/material/TableCell'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'

export const StyledTableCell = styled(TableCell)({
   color: 'grayText',
   textAlign: 'center',
})

export const StyledTable = styled(Table)({
   marginTop: 10,
   WebkitBoxShadow: '0px 0px -4px 0px rgba(66, 68, 90, 1)',
   MozBoxShadow: '0px 0px -4px 0px rgba(66, 68, 90, 1)',
   boxShadow: ' 0px 0px 10px -4px rgba(66, 68, 90, 1)',
   borderRadius: '10px',
   backgroundColor: 'rgba(255,255,255,0.7)',
   '>*': {
      color: 'grayText',
   },
})

export const StyledLink = styled('a')(({ theme }) => ({
   color: 'black',
   textDecoration: 'none',
   '&:hover': {
      fontWeight: 'bold',
      color: theme.palette.primary.main,
      cursor: 'pointer',
   },
}))

export const StyledCopyIcon = styled(FileCopyOutlinedIcon)(({ theme }) => ({
   marginLeft: 10,
   marginBottom: -2,
   color: 'grayText',
   fontSize: 16,
   '&:hover': {
      color: theme.palette.primary.light,
   },
}))

export const StyledContentTableCell = styled(TableCell)({
   padding: '6px 4px 6px 4px',
   textAlign: 'center',
})

export const StyledDeleteIcon = styled(DeleteIcon)({
   color: 'grayText',
   '&:hover': {
      cursor: 'pointer',
      color: 'red',
   },
})

export const StyledTableRow = styled(TableRow)({
   '&:hover': {
      backgroundColor: 'rgba(28,202,255,0.03)',
      cursor: 'pointer',
   },
})
