import Typography from '@mui/material/Typography'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import { useTranslation } from 'react-i18next'
import TableRow from '@mui/material/TableRow'
import Table from '@mui/material/Table'
import Box from '@mui/material/Box'
import React from 'react'

import { StyledTableCell } from 'shared/components/StyledTableComponents'
import RadiusTableWrapper from 'shared/components/RadiusTableWrapper'
import Tooltip from '@mui/material/Tooltip'

interface Props {
   data: OrderProductType[]
   netDeliveryCost?: number
   grossDeliveryCost?: number
   formattedGrossValue: string
   formattedNetValue: string
}

export default function OrderedTable({
   data,
   formattedGrossValue,
   formattedNetValue,
   netDeliveryCost,
   grossDeliveryCost,
}: Props) {
   const { t } = useTranslation(['TRANSLATION', 'ORDER_SUMMARY'])

   function returnComment(name: PropType<OrderProductType, 'status'>) {
      switch (name) {
         case 'NOT_AVAILABLE':
            return t('ORDER_SUMMARY:tables.notAvailable')
         case 'PRICE_HAS_CHANGED':
            return t('ORDER_SUMMARY:tables.priceHasChanged')
         case 'WHOLESALE_CONNECTION_PROBLEM':
            return t('ORDER_SUMMARY:tables.wholesaleConnectionProblem')
         case 'TOO_LOW_AMOUNT':
            return t('ORDER_SUMMARY:tables.tooLowAmount')
         case 'DELIVERY_PROBLEM':
            return t('ORDER_SUMMARY:tables.deliveryProblem')
         case 'INDEX_PROBLEM':
            return t('ORDER_SUMMARY:tables.indexProblem')
         case 'PAYMENTS_PROBLEM':
            return t('ORDER_SUMMARY:tables.paymentsProblem')
         case 'WHOLESALE_ERROR':
            return t('ORDER_SUMMARY:tables.wholesaleError')
         case 'PARTIALLY_AVAILABLE':
            return t('ORDER_SUMMARY:tables.partiallyAvailable')
         default:
            return null
      }
   }

   function returnWholesaleErrorMessage(
      status: PropType<OrderProductType, 'status'>,
      wholesaleErrorMessage?: Nullable<string>
   ) {
      if (status !== 'AVAILABLE' && status !== 'PARTIALLY_AVAILABLE') {
         if (!!wholesaleErrorMessage && !wholesaleErrorMessage?.includes('NOT_HANDLED')) {
            return wholesaleErrorMessage
         }
         if (status === 'PRICE_HAS_CHANGED') {
            return t('ORDER_SUMMARY:tables.priceHasChanges')
         }
         if (status === 'TOO_LOW_AMOUNT') {
            return t('ORDER_SUMMARY:tables.tooLowAmount')
         }
         return t('ORDER_SUMMARY:tables.wholesaleNoResponse')
      }
      return ''
   }

   function getHighlightColor(product: OrderProductType) {
      if (product.status === 'AVAILABLE') {
         return 'transparent'
      }
      if (product.amount !== product.requestedAmount && product.amount !== 0) {
         return 'rgb(255, 136, 0,0.7)'
      }
      return 'rgba(255,0,0,0.5)'
   }

   const notOrderedProducts = data.filter((item) => item.status !== 'AVAILABLE').length
   return (
      <Box>
         <Typography variant="body1" color="text.secondary">
            {t('ORDER_SUMMARY:tables.orderedProducts')}
         </Typography>
         <RadiusTableWrapper>
            <Table sx={{ border: '1px solid rgba(224, 224, 224, 1)', width: 1200 }}>
               <TableHead sx={{ backgroundColor: 'rgba(33,147,224, 0.1)' }}>
                  <TableRow>
                     <StyledTableCell>{t('TRANSLATION:columnNames.partIndex')}</StyledTableCell>
                     <StyledTableCell>{t('ORDER_SUMMARY:tables.orderNumber')}</StyledTableCell>
                     <StyledTableCell>{t('TRANSLATION:columnNames.wholesaler')}</StyledTableCell>
                     <StyledTableCell>{t('TRANSLATION:columnNames.quantity')}</StyledTableCell>
                     <StyledTableCell>{t('TRANSLATION:columnNames.netPrice')}</StyledTableCell>
                     <StyledTableCell>VAT</StyledTableCell>
                     <StyledTableCell>{t('TRANSLATION:columnNames.grossValue')}</StyledTableCell>
                     {!!notOrderedProducts && (
                        <>
                           <StyledTableCell>{t('TRANSLATION:columnNames.status')}</StyledTableCell>
                           <StyledTableCell>{t('ORDER_SUMMARY:tables.wholesaleInfo')}</StyledTableCell>
                        </>
                     )}
                  </TableRow>
               </TableHead>
               <TableBody>
                  {data.map((item) => (
                     <TableRow sx={{ backgroundColor: getHighlightColor(item) }} key={item.productUuid}>
                        <StyledTableCell>{item.index}</StyledTableCell>
                        <StyledTableCell>{item.wholesaleOrderNumber}</StyledTableCell>
                        <StyledTableCell>
                           <img
                              height="20px"
                              width="auto"
                              src={`${process.env.REACT_APP_FILES_URL}/img/logos-wholesalers/${item.wholesaleName}.png`}
                              alt={item.wholesaleName}
                           />
                        </StyledTableCell>
                        <Tooltip
                           disableHoverListener={item.amount === 0}
                           title={t('TRANSLATION:requestedAmountTooltip', {
                              amount: item.amount,
                              requestedAmount: item.requestedAmount,
                           })}
                        >
                           <StyledTableCell>
                              {item.amount !== 0 && item.amount !== item.requestedAmount
                                 ? `${item.amount}/${item.requestedAmount}`
                                 : item.amount}
                           </StyledTableCell>
                        </Tooltip>
                        <StyledTableCell>
                           {item.formattedPrice} {item.formattedConvertedPrice && `(${item.formattedConvertedPrice})`}
                        </StyledTableCell>
                        <StyledTableCell>{item.taxRate}%</StyledTableCell>
                        <StyledTableCell>
                           {item.formattedGrossValue}{' '}
                           {item.formattedConvertedGrossValue && `(${item.formattedConvertedGrossValue})`}
                        </StyledTableCell>
                        {!!notOrderedProducts && (
                           <>
                              <StyledTableCell>
                                 {item.status !== 'AVAILABLE' && returnComment(item.status)}
                              </StyledTableCell>
                              <StyledTableCell>
                                 {item.status !== 'AVAILABLE' &&
                                    returnWholesaleErrorMessage(item.status, item.wholesaleErrorMessage)}
                              </StyledTableCell>
                           </>
                        )}
                     </TableRow>
                  ))}
                  <TableRow>
                     <StyledTableCell colSpan={2} />
                     <StyledTableCell colSpan={2} sx={{ textAlign: 'end' }}>
                        {t('TRANSLATION:columnNames.netSum')}
                     </StyledTableCell>
                     <StyledTableCell>{formattedNetValue}</StyledTableCell>
                     <StyledTableCell sx={{ textAlign: 'center', minWidth: 100 }}>
                        {t('TRANSLATION:columnNames.grossSum')}
                     </StyledTableCell>
                     <StyledTableCell>{formattedGrossValue}</StyledTableCell>
                  </TableRow>
                  {netDeliveryCost && (
                     <TableRow>
                        <StyledTableCell colSpan={4} />
                        <StyledTableCell>{t('TRANSLATION:deliveryCost')}</StyledTableCell>
                        <StyledTableCell>
                           {t('TRANSLATION:price', { price: netDeliveryCost.toFixed(2) })} {t('TRANSLATION:NET')}
                        </StyledTableCell>
                        <StyledTableCell>
                           {t('TRANSLATION:price', { price: grossDeliveryCost ? grossDeliveryCost.toFixed(2) : 0 })}{' '}
                           {t('TRANSLATION:GROSS')}
                        </StyledTableCell>
                     </TableRow>
                  )}
               </TableBody>
            </Table>
         </RadiusTableWrapper>
         {!!data.filter((item) => item.status !== 'AVAILABLE').length && (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
               <Typography variant="body1">{t('ORDER_SUMMARY:notOrderedProducts')}</Typography>
               <Typography variant="body1">{t('ORDER_SUMMARY:notOrderedProductsBack')}</Typography>
            </Box>
         )}
      </Box>
   )
}
