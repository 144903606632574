/* eslint-disable array-callback-return */
import CircularProgress from '@mui/material/CircularProgress'
import React, { useEffect, useRef, useState } from 'react'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import { useTranslation } from 'react-i18next'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import Dialog from '@mui/material/Dialog'
import Table from '@mui/material/Table'
import Box from '@mui/material/Box'
import { CSVLink } from 'react-csv'

import HiddenWholesalerLogo from 'shared/components/HiddenWholesalerLogo'
import { StyledTableCell } from 'shared/components/StyledTableComponents'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import getProductName from 'shared/utils/helpers/getProductName'
import { Order } from 'shared/store/useOrdersHistory/types'

import { IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import { StoredOrderDetails, ReportType, OrderProduct } from '../../types'
import { getOrderDetailsService, getCsvOrderReportService } from '../../services'

// Everything which is commented in rating will be used in future
interface Props {
   orderUuids: string[]
   open: boolean
   title: string
   onClose: () => void
   details: StoredOrderDetails[]
   updateDetailsStore: (newRecord: StoredOrderDetails) => void
   priceType?: PriceType
   orderType?: PropType<Order, 'orderType'>
   formattedNetValue?: string
   formattedGrossValue?: string
   netDeliveryCost?: number
   grossDeliveryCost?: number
   formattedServicesValue?: string
}

const columnNames = [
   'productName',
   'orderNumber',
   'producer',
   'partIndex',
   'quantity',
   'wholesaler',
   'price',
   'clientPrice',
   // 'rateProduct',
]

const baselinkerColumnNames = ['productName', 'orderNumber', 'producer', 'partIndex', 'quantity', 'wholesaler', 'price']

export default function OrderDetails({
   orderUuids,
   open,
   onClose,
   details,
   title,
   updateDetailsStore,
   priceType,
   orderType,
   formattedGrossValue,
   formattedNetValue,
   formattedServicesValue,
   netDeliveryCost,
   grossDeliveryCost,
}: Props) {
   // const [rating, setRating] = useState<RatingType>({ conformRate: 0, productionQualityRate: 0, priceToQualityRate: 0 })
   const [isLoading, setIsLoading] = useState(false)
   const [data, setData] = useState<PropType<StoredOrderDetails, 'data'>>({
      products: [],
      additionalServices: [],
   })
   const [reportData, setReportData] = useState<ReportType>()
   const componentRef = useRef(null)
   const { t } = useTranslation(['TRANSLATION', 'ORDERS_HISTORY'])
   const isDetailsStored = useRef(false)

   // function handleRate(productCartUuid: string, productUuid: string) {
   //    setIsLoading(true)
   //    const payload: RatingPayload = {
   //       conformRate: rating.conformRate,
   //       priceToQualityRate: rating.priceToQualityRate,
   //       productionQualityRate: rating.productionQualityRate,
   //       orderUuid: orderUuids[0],
   //       productCartUuid,
   //       productUuid,
   //    }
   //    addRatingService(payload)
   //       .then(() => updateDetailsStore({ id: orderUuids[0], data }))
   //       .finally(() => setIsLoading(false))
   // }

   async function init() {
      const storedDetails = details.find((item) => item.id === orderUuids[0])
      if (storedDetails) {
         isDetailsStored.current = true
         setData(storedDetails.data)
      } else {
         setIsLoading(true)
         const req = await getOrderDetailsService(orderUuids)
         if (req) {
            setData(req)
         }
      }
      getCsvOrderReportService(orderUuids).then((res) => {
         setReportData(res)
      })
      setIsLoading(false)
   }

   function getHighlightColor(product: OrderProduct) {
      if (product.status === 'AVAILABLE') {
         return 'transparent'
      }
      if (product.amount !== product.requestedAmount && product.amount !== 0) {
         return 'rgb(255, 136, 0,0.7)'
      }
      return 'rgba(255,0,0,0.5)'
   }

   function generateCSV() {
      const CSVData: (string | number)[][] = []
      const titleRow = [
         t('TRANSLATION:columnNames.partIndex'),
         t('TRANSLATION:columnNames.producer'),
         t('TRANSLATION:columnNames.wholesaler'),
         t('TRANSLATION:columnNames.netPrice'),
         t('TRANSLATION:columnNames.netSum'),
         t('TRANSLATION:columnNames.grossPrice'),
         t('TRANSLATION:columnNames.grossSum'),
         'VAT',
         t('TRANSLATION:columnNames.orderedAmount'),
         t('TRANSLATION:columnNames.notOrderedAmount'),
         t('TRANSLATION:columnNames.status'),
         t('TRANSLATION:columnNames.wholesaleErrorMessage'),
      ]
      CSVData.push(titleRow)
      const services = [t('TRANSLATION:columnNames.services')]
      if (reportData) {
         reportData.orders.map((order) => {
            order.wholesales.map((wholesale) => {
               wholesale.orderedProducts.map((product) => {
                  CSVData.push([
                     product.index,
                     product.producerName,
                     wholesale.wholesaleName,
                     product.unitNetPrice,
                     product.netPrice,
                     product.unitGrossPrice,
                     product.grossPrice,
                     product.vat,
                     product.orderedAmount,
                     product.notOrderedAmount,
                     product.orderProductStatus,
                     product.wholesaleErrorMessage || '',
                  ])
               })
            })
            if (order.additionalServices.length > 0) {
               CSVData.push(services)
               order.additionalServices.map((service) => {
                  CSVData.push([
                     service.name,
                     '',
                     '',
                     service.formattedNetPrice,
                     service.formattedNetValue,
                     service.formattedGrossPrice,
                     service.formattedGrossValue,
                     service.taxRate,
                     service.amount,
                     '',
                     '',
                     '',
                  ])
               })
            }
         })
      }
      return (
         <CSVLink style={{ textAlign: 'right' }} filename={title} data={CSVData}>
            <ContainedButton variant="contained">{t('ORDERS_HISTORY:generateCSV')}</ContainedButton>
         </CSVLink>
      )
   }

   function generateImportCSV() {
      const CSVData: (string | number)[][] = []
      const titleRow = [
         t('TRANSLATION:columnNames.requestedIndex'),
         t('TRANSLATION:columnNames.partIndex'),
         t('TRANSLATION:columnNames.producer'),
         t('TRANSLATION:columnNames.wholesaler'),
         t('TRANSLATION:columnNames.netPrice'),
         t('TRANSLATION:columnNames.netSum'),
         t('TRANSLATION:columnNames.grossPrice'),
         t('TRANSLATION:columnNames.grossSum'),
         t('TRANSLATION:columnNames.priceThreshold'),
         'VAT',
         t('TRANSLATION:columnNames.requestedAmount'),
         t('TRANSLATION:columnNames.orderedAmount'),
         t('TRANSLATION:columnNames.notOrderedAmount'),
         t('TRANSLATION:columnNames.status'),
         t('TRANSLATION:columnNames.wholesaleErrorMessage'),
      ]
      CSVData.push(titleRow)
      const notFoundIndexes = [t('TRANSLATION:columnNames.notFoundIndexes')]
      const rejectedIndexes = [t('TRANSLATION:columnNames.rejectedIndexes')]
      const services = [t('TRANSLATION:columnNames.services')]
      if (reportData) {
         reportData.orders.map((order) => {
            order.wholesales.map((wholesale) => {
               wholesale.orderedProducts.map((product) => {
                  CSVData.push([
                     product.requestedIndex,
                     product.index,
                     product.producerName,
                     wholesale.wholesaleName,
                     product.unitNetPrice,
                     product.netPrice,
                     product.unitGrossPrice,
                     product.grossPrice,
                     product.priceThreshold,
                     product.vat,
                     product.requestedAmount,
                     product.orderedAmount,
                     product.notOrderedAmount,
                     product.orderProductStatus,
                     product.wholesaleErrorMessage || '',
                  ])
               })
            })
            if (order.notFoundIndexes.length > 0) {
               CSVData.push(notFoundIndexes)
               order.notFoundIndexes.map((index) => {
                  CSVData.push(['', index])
               })
            }
            if (order.rejectedIndexes.length > 0) {
               CSVData.push(rejectedIndexes)
               order.rejectedIndexes.map((index) => {
                  CSVData.push(['', index])
               })
            }
            if (order.additionalServices.length > 0) {
               CSVData.push(services)
               order.additionalServices.map((service) => {
                  CSVData.push([
                     '',
                     service.name,
                     '',
                     '',
                     service.formattedNetPrice,
                     service.formattedNetValue,
                     service.formattedGrossPrice,
                     service.formattedGrossValue,
                     '',
                     service.taxRate,
                     service.amount,
                     '',
                     '',
                     '',
                  ])
               })
            }
         })
      }

      return (
         <CSVLink style={{ textAlign: 'right' }} filename={title} data={CSVData}>
            <ContainedButton variant="contained">{t('ORDERS_HISTORY:generateCSV')}</ContainedButton>
         </CSVLink>
      )
   }

   useEffect(() => {
      init()
   }, [])

   const handleOnClose = () => {
      if (!isDetailsStored.current && orderType) {
         updateDetailsStore({ orderType, id: orderUuids[0], data })
      }
      onClose()
   }

   return (
      <Dialog open={open} onClose={handleOnClose} fullWidth scroll="paper" maxWidth="xl">
         <DialogContent ref={componentRef} style={{ minHeight: 300, display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
               <Typography variant="h4" color="primary">
                  {title}
               </Typography>
               <IconButton onClick={handleOnClose}>
                  <Close />
               </IconButton>
            </Box>
            <Table sx={{ width: '100%', marginY: 3 }}>
               <TableHead sx={{ backgroundColor: 'rgba(33,147,224, 0.1)' }}>
                  <TableRow>
                     {orderType !== 'BASELINKER' &&
                        columnNames.map((column) => (
                           <StyledTableCell sx={{ textAlign: 'center' }} key={column} width={250}>
                              <h3>
                                 {t(`TRANSLATION:columnNames.${column}`)}{' '}
                                 {(column === 'price' || column === 'clientPrice') && t(`TRANSLATION:${priceType}`)}
                              </h3>
                           </StyledTableCell>
                        ))}
                     {orderType === 'BASELINKER' &&
                        baselinkerColumnNames.map((column) => (
                           <StyledTableCell sx={{ textAlign: 'center' }} key={column} width={250}>
                              <h3>
                                 {t(`TRANSLATION:columnNames.${column}`)}{' '}
                                 {(column === 'price' || column === 'clientPrice') && t(`TRANSLATION:${priceType}`)}
                              </h3>
                           </StyledTableCell>
                        ))}
                     {orderType === 'GROUPED_BUY_NOW' && (
                        <>
                           <StyledTableCell sx={{ textAlign: 'center' }} width={250}>
                              {t('TRANSLATION:columnNames.userName')}
                           </StyledTableCell>
                           <StyledTableCell sx={{ textAlign: 'center' }} width={250}>
                              {t('TRANSLATION:columnNames.orderTime')}
                           </StyledTableCell>
                        </>
                     )}
                     {data.products.some((product) => product.status !== 'AVAILABLE') && (
                        <StyledTableCell sx={{ textAlign: 'center' }} width={250}>
                           {t('TRANSLATION:columnNames.wholesaleErrorMessage')}
                        </StyledTableCell>
                     )}
                  </TableRow>
               </TableHead>
               <TableBody>
                  {isLoading ? (
                     <TableRow>
                        <TableCell colSpan={orderType === 'GROUPED_BUY_NOW' ? 9 : 8}>
                           <Box display="flex" justifyContent="center" alignItems="center" height={200}>
                              <CircularProgress />
                           </Box>
                        </TableCell>
                     </TableRow>
                  ) : (
                     data.products.map((product) => {
                        // const isAverageRate = !!product.rating.averageRate
                        return (
                           <TableRow key={product.uuid} sx={{ backgroundColor: getHighlightColor(product) }}>
                              <StyledTableCell width={250}>{getProductName(product)}</StyledTableCell>
                              <StyledTableCell>{product.wholesaleOrderNumber}</StyledTableCell>
                              <StyledTableCell>
                                 <Tooltip title={product.producerName}>
                                    <img
                                       src={`https://files.motorro.eu/img?name=${product.producerNameWithoutSpecialChars.toLowerCase()}`}
                                       height="20px"
                                       alt={product.producerNameWithoutSpecialChars.toLowerCase()}
                                       width="auto"
                                    />
                                 </Tooltip>
                              </StyledTableCell>
                              <StyledTableCell>{product.index}</StyledTableCell>
                              <Tooltip
                                 disableHoverListener={product.amount === 0}
                                 title={t('TRANSLATION:requestedAmountTooltip', {
                                    amount: product.amount,
                                    requestedAmount: product.requestedAmount,
                                 })}
                              >
                                 <StyledTableCell>
                                    {product.amount !== 0 && product.amount !== product.requestedAmount
                                       ? `${product.amount}/${product.requestedAmount}`
                                       : product.amount}
                                 </StyledTableCell>
                              </Tooltip>
                              <StyledTableCell>
                                 <HiddenWholesalerLogo
                                    wholesalerName={product.wholesaleName}
                                    imgWidth="auto"
                                    imgHeight="20px"
                                 />
                              </StyledTableCell>
                              <StyledTableCell>
                                 {priceType === 'NET' ? product.formattedPrice : product.formattedGrossPrice}
                                 {typeof product.formattedConvertedPrice !== 'undefined' &&
                                    (priceType === 'NET'
                                       ? ` (${product.formattedConvertedPrice})`
                                       : ` (${product.formattedConvertedGrossPrice})`)}
                              </StyledTableCell>
                              {orderType !== 'BASELINKER' && (
                                 <StyledTableCell>
                                    {priceType === 'NET'
                                       ? product.formattedClientNetPrice
                                       : product.formattedClientGrossPrice}
                                 </StyledTableCell>
                              )}
                              {orderType === 'GROUPED_BUY_NOW' && (
                                 <StyledTableCell>{product.username || ''}</StyledTableCell>
                              )}
                              {data.products.some((p) => p.status !== 'AVAILABLE') && (
                                 <StyledTableCell>{product.wholesaleErrorMessage ?? ''}</StyledTableCell>
                              )}
                              {/* <StyledTableCell>
                                 <Tooltip
                                    leaveDelay={300}
                                    title={
                                       <div
                                          style={{
                                             display: 'flex',
                                             flexDirection: 'column',
                                          }}
                                       >
                                          {t('TRANSLATION:columnNames.qualityRate')}
                                          <Rating
                                             readOnly={isAverageRate}
                                             value={
                                                product.rating.averageRate
                                                   ? product.rating.productionQualityRate
                                                   : rating.productionQualityRate
                                             }
                                             onChange={(event, newValue) => {
                                                setRating({ ...rating, productionQualityRate: Number(newValue) })
                                             }}
                                             precision={0.5}
                                          />
                                          {t('TRANSLATION:columnNames.conformRate')}
                                          <Rating
                                             readOnly={isAverageRate}
                                             value={
                                                product.rating.averageRate
                                                   ? product.rating.conformRate
                                                   : rating.conformRate
                                             }
                                             onChange={(event, newValue) => {
                                                setRating({ ...rating, conformRate: Number(newValue) })
                                             }}
                                             precision={0.5}
                                          />
                                          {t('TRANSLATION:columnNames.priceQualityRate')}
                                          <Rating
                                             readOnly={isAverageRate}
                                             value={
                                                isAverageRate
                                                   ? product.rating.priceToQualityRate
                                                   : rating.priceToQualityRate
                                             }
                                             onChange={(event, newValue) => {
                                                setRating({ ...rating, priceToQualityRate: Number(newValue) })
                                             }}
                                             precision={0.5}
                                          />
                                          {product.rating.averageRate ? null : (
                                             <Button
                                                disabled={isLoading}
                                                onClick={() => handleRate(product.uuid, product.productUuid)}
                                             >
                                                {t('TRANSLATION:columnNames.rateButton')}
                                             </Button>
                                          )}
                                       </div>
                                    }
                                 >
                                    <Box
                                       sx={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                          flexDirection: 'column',
                                       }}
                                    >
                                       {t('TRANSLATION:columnNames.averageRate')}
                                       <Rating
                                          sx={{ alignSelf: 'center' }}
                                          readOnly
                                          value={product.rating.averageRate}
                                       />
                                    </Box>
                                 </Tooltip> */}
                              {/* </StyledTableCell> */}
                              {orderType === 'GROUPED_BUY_NOW' && (
                                 <StyledTableCell>{product.orderDate || ''}</StyledTableCell>
                              )}
                           </TableRow>
                        )
                     })
                  )}
                  {netDeliveryCost && (
                     <TableRow>
                        <StyledTableCell colSpan={5} />
                        <StyledTableCell>{t('TRANSLATION:deliveryCost')}</StyledTableCell>
                        <StyledTableCell>
                           {t('TRANSLATION:price', { price: netDeliveryCost.toFixed(2) })} {t('TRANSLATION:NET')}
                        </StyledTableCell>
                        <StyledTableCell>
                           {t('TRANSLATION:price', { price: grossDeliveryCost ? grossDeliveryCost.toFixed(2) : 0 })}{' '}
                           {t('TRANSLATION:GROSS')}
                        </StyledTableCell>
                     </TableRow>
                  )}
                  <TableRow sx={{ backgroundColor: 'rgba(33,147,224, 0.1)' }}>
                     <StyledTableCell
                        sx={{ fontWeight: 'bold', fontSize: 14 }}
                        colSpan={orderType === 'GROUPED_BUY_NOW' ? 5 : 4}
                     >
                        {`${t('TRANSLATION:columnNames.netSum')}: ${formattedNetValue}`}
                     </StyledTableCell>
                     <StyledTableCell sx={{ fontWeight: 'bold', fontSize: 14 }} colSpan={5}>
                        {`${t('TRANSLATION:columnNames.grossSum')}: ${formattedGrossValue}`}
                     </StyledTableCell>
                  </TableRow>
                  {data.additionalServices.length > 0 &&
                     data.additionalServices.map((service) => (
                        <TableRow>
                           <StyledTableCell colSpan={4}>{service.name}</StyledTableCell>
                           <StyledTableCell>{service.amount}</StyledTableCell>
                           <StyledTableCell />
                           <StyledTableCell />
                           <StyledTableCell>
                              {priceType === 'NET' ? service.formattedNetPrice : service.formattedGrossPrice}
                           </StyledTableCell>
                        </TableRow>
                     ))}
                  {data.additionalServices.length > 0 && (
                     <TableRow sx={{ backgroundColor: 'rgba(33,147,224, 0.1)' }}>
                        <StyledTableCell colSpan={6} />
                        <StyledTableCell colSpan={3} sx={{ fontWeight: 'bold', fontSize: 14 }}>
                           {`${t('TRANSLATION:columnNames.servicesSum')}: ${formattedServicesValue}`}
                        </StyledTableCell>
                     </TableRow>
                  )}
               </TableBody>
            </Table>
            {orderType === 'CART_CSV' ? generateImportCSV() : generateCSV()}
         </DialogContent>
      </Dialog>
   )
}
