import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import 'slick-carousel/slick/slick.css'
import 'locales/i18n'

import Unauthorized from 'views/Unauthorized'
import Authorized from 'views/Authorized'
import { ThemeConfig } from 'config/theme'
import { LastVisitedPageProvider } from 'shared/hooks/LastPageHook'
import PrivateRoute from 'shared/components/PrivateRoute'
import { UserRoles } from 'shared/consts'

import reportWebVitals from './reportWebVitals'

const queryClient = new QueryClient({
   defaultOptions: {
      queries: {
         retryDelay: 20000,
         retry: 2,
         refetchOnWindowFocus: false,
      },
   },
})

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
   <React.StrictMode>
      <ThemeConfig>
         <QueryClientProvider client={queryClient}>
            <BrowserRouter>
               <LastVisitedPageProvider>
                  <Routes>
                     <Route path="*" element={<Unauthorized />} />
                     <Route
                        path="dashboard/*"
                        element={
                           <PrivateRoute
                              component={Authorized}
                              roles={[
                                 UserRoles.MAIN_LOCALIZATION_ADMIN,
                                 UserRoles.PREMIUM,
                                 UserRoles.REGISTERED_USER,
                                 UserRoles.SUBSCRIPTION,
                              ]}
                           />
                        }
                     />
                  </Routes>
               </LastVisitedPageProvider>
            </BrowserRouter>
            <ReactQueryDevtools initialIsOpen />
         </QueryClientProvider>
      </ThemeConfig>
   </React.StrictMode>
)

reportWebVitals()
