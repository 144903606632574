import { styled } from '@mui/material'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'

export const ProductTableRow = styled(TableRow)({
   background: 'white',
   '&:hover': {
      backgroundColor: 'rgba(28,202,255,0.05)',
      cursor: 'pointer',
   },
})

export const StyledSpan = styled('span')(({ theme }) => ({
   marginLeft: 5,
   color: theme.palette.primary.main,
   fontSize: 17,
   fontWeight: 'bolder',
}))

export const BoldTypography = styled(Typography)({
   fontWeight: 'bold',
   color: 'grayText',
})

export const StyledAvailabilityDot = styled('div')(({ color }) => ({
   width: 15,
   margin: '0 auto',
   height: 15,
   borderRadius: 50,
   backgroundColor: color,
}))
