import React, { useEffect, useRef, useState } from 'react'
import { Routes, Route, Navigate, useLocation, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { jwtDecode } from 'jwt-decode'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'

import { useLogisticMinimumsQuery } from 'shared/store/useLogisticMinimums'
import { JwtTokenData, StateType } from 'shared/store/useLogin/types'
import consoleMessage from 'shared/utils/helpers/consoleMessage'
import NotificationProvider from 'shared/context/Notifications'
import CustomSnackbar from 'shared/components/CustomSnackbar'
import { useLocationsQuery } from 'shared/store/useLocations'
import { LocalStorageKeys, UserRoles } from 'shared/consts'
import { useSettingsQuery } from 'shared/store/useSettings'
import PrivateRoute from 'shared/components/PrivateRoute'
import useLogin from 'shared/store/useLogin'
import NotFound from 'shared/components/404'
import routes from 'config/routes'

import LogisticsMinimumDialog from './components/LogisticsMinimumDialog'
import SwapLocationDialog from './components/SwapLocationDialog'
import AppVersionModal from './components/AppVersionModal'
import Layout from './components/Layout'
import Homepage from './views/Homepage'
import { en, pl, de } from './locales'

export default function Authorized() {
   const { i18n } = useTranslation()
   const [openLogisticMinimumsDialog, setOpenLogisticMinimumsDialog] = useState(false)
   const [swapLocationDialog, setSwapLocationDialog] = useState(false)
   const [openAppVersionDialog, setOpenAppVersionDialog] = useState(false)
   const { data } = useSettingsQuery()
   const { user } = useLogin()
   const location = useLocation()
   const [searchParams] = useSearchParams()
   const queryClient = useQueryClient()
   const { data: locationsData } = useLocationsQuery()
   const { data: logisticMinimumsData } = useLogisticMinimumsQuery(false)

   const logisticMinimumsDialogInterval = useRef<NodeJS.Timer>()
   i18n.addResourceBundle('pl-PL', 'AUTHORIZED', pl)
   i18n.addResourceBundle('en-EN', 'AUTHORIZED', en)
   i18n.addResourceBundle('de-DE', 'AUTHORIZED', de)

   function checkLMTime() {
      if (data) {
         const actuallyTime = new Date()
         const popupTime = new Date(
            actuallyTime.getFullYear(),
            actuallyTime.getMonth(),
            actuallyTime.getDate(),
            Number(data.userSettings.preferences.time.split(':')[0]),
            Number(data.userSettings.preferences.time.split(':')[1]),
            actuallyTime.getSeconds(),
            actuallyTime.getMilliseconds()
         )
         if (actuallyTime.getTime() === popupTime.getTime()) {
            setOpenLogisticMinimumsDialog(true)
            if (logisticMinimumsDialogInterval.current) {
               clearInterval(logisticMinimumsDialogInterval.current as NodeJS.Timeout)
            }
         }
      }
   }

   function handleLocationDialogClose() {
      setSwapLocationDialog(false)
      window.localStorage.setItem('chooseLocationModal', 'true')
   }

   useEffect(() => {
      if (data && locationsData && logisticMinimumsData) {
         const shouldAppear =
            data.userSettings.preferences.dontShowLocationDialog !== 'true' &&
            locationsData.locations.filter((item) => !item.locationSettings.disabled).length > 1
         if (window.localStorage.getItem('chooseLocationModal') === null && shouldAppear) {
            setSwapLocationDialog(true)
         } else {
            window.localStorage.setItem('chooseLocationModal', 'true')
         }
      }
      if (process.env.REACT_APP_API_URL?.includes('dev') && !document.title.includes('DEV')) {
         document.title += ' - DEV'
      }
      if (logisticMinimumsData) {
         if (
            data?.userSettings.preferences.displayMinPopup === 'true' &&
            logisticMinimumsData.logisticMinimums.filter((item) => item.remainingAmount !== 0).length > 0
         ) {
            if (!logisticMinimumsDialogInterval.current) {
               consoleMessage('APP', 'SETUPING INTERVAL ACTIVITY LM', 'success')
               logisticMinimumsDialogInterval.current = setInterval(checkLMTime, 60000)
            }
         }
      }

      return () => {
         if (logisticMinimumsDialogInterval.current) {
            consoleMessage('APP', 'REMOVING INTERVAL ACTIVITY LM', 'success')
            clearInterval(logisticMinimumsDialogInterval.current as NodeJS.Timeout)
         }
      }
   }, [data, locationsData])

   useEffect(() => {
      const token = localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN)
      if (token) {
         const actualUser = jwtDecode<JwtTokenData>(token)

         const newUserData: StateType = {
            frontendVersionTimestamp: actualUser.frontend_version_timestamp,
            loginStatus: 'logged',
            roles: actualUser.authorities.split(',') as UserRoles[],
            uuid: actualUser.sub,
         }
         queryClient.setQueryData('user', newUserData)
      }

      if (user.frontendVersionTimestamp > Number(process.env.VERSION_TIMESTAMP)) {
         setOpenAppVersionDialog(true)
      }
   }, [user, window.localStorage.getItem('token'), location])

   return (
      <NotificationProvider>
         <ReactErrorBoundary
            FallbackComponent={(props) => {
               props.resetErrorBoundary()
               return (
                  <Navigate
                     state={{
                        error: props.error,
                        props: searchParams.toString(),
                        path: location.pathname,
                        variant: 'frontend',
                     }}
                     to="/dashboard/error-page"
                  />
               )
            }}
         >
            <Routes>
               <Route path="/" element={<Layout />}>
                  <Route index element={<Homepage />} />
                  {routes.map((route) => (
                     <Route
                        key={route.name}
                        path={route.path}
                        element={
                           <PrivateRoute needSub={route.needSub} component={route.element} roles={route.authority} />
                        }
                     />
                  ))}
                  <Route path="*" element={<NotFound />} />
               </Route>
            </Routes>
         </ReactErrorBoundary>
         <CustomSnackbar />
         {logisticMinimumsData && (
            <LogisticsMinimumDialog
               open={openLogisticMinimumsDialog}
               data={logisticMinimumsData}
               handleClose={() => setOpenLogisticMinimumsDialog(false)}
            />
         )}
         <SwapLocationDialog open={swapLocationDialog} handleClose={handleLocationDialogClose} />
         <AppVersionModal open={openAppVersionDialog} />
      </NotificationProvider>
   )
}
