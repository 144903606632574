import { styled } from '@mui/material'
import PrintIcon from '@mui/icons-material/Print'
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined'

export const StyledFullPrintIcon = styled(PrintIcon)(({ theme }) => ({
   color: 'grayText',
   '&:hover': {
      color: theme.palette.primary.main,
   },
}))

export const StyledClientPrintIcon = styled(LocalPrintshopOutlinedIcon)(({ theme }) => ({
   color: 'grayText',
   '&:hover': {
      color: theme.palette.primary.main,
   },
}))
