import { styled } from '@mui/material'
import Card from '@mui/material/Card'

export const StyledCard = styled(Card)(({ theme }) => ({
   width: 160,
   height: 160,
   display: 'flex',
   justifyContent: 'center',
   alignItems: 'center',
   borderRadius: 5,
   '&:hover': {
      cursor: 'pointer',
      boxShadow: `0 0 1.5em ${theme.palette.primary.main}`,
   },
}))
export const StyledImg = styled('img')({
   height: 25,
   filter: 'grayscale(1)',
   opacity: 0.6,
})
